import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'

import injectNestedIntlProvider from '../../../components/NestedIntlProvider'
import PageLayout from '../../../components/layout'
import {
  ServicesFrontendBox,
  ServicesBackednBox,
  ServicesWebsitesBox,
  ServicesWritingBox,
} from '../../../components/boxes/boxes'
import BoxesLayout from '../../../components/boxes/BoxesLayout'
import PageSummary from '../../../components/PageSummary'
import Block from '../../../components/Block'
import Hr from '../../../components/Hr'

const other_services = ['support', 'consulting']

const Page = ({ data }) => (
  <FormattedMessage id="pages.services.title">
    {title => (
      <PageLayout
        title={title}
        pageSummary={
          <PageSummary
            title={<FormattedMessage id="pages.services.title" />}
            lead={<FormattedMessage id="pages.services.description_1" />}
            imageFluid={data.summaryImage && data.summaryImage.childImageSharp.fluid}
          />
        }
        pageClassName="page--services"
      >
        <BoxesLayout>
          <ServicesFrontendBox />
          <ServicesBackednBox />
          <ServicesWebsitesBox />
          <ServicesWritingBox />
        </BoxesLayout>

        <Hr empty />

        <Block title={<FormattedMessage id="pages.other_services.title" />}>
          {other_services.map((key, index) => (
            <div key={index} className="im-service">
              <h6>
                <FormattedMessage id={`pages.${key}.title`} />
              </h6>
              <p>
                <FormattedMessage id={`pages.${key}.lead`} />
              </p>
            </div>
          ))}
        </Block>
      </PageLayout>
    )}
  </FormattedMessage>
)

export default injectNestedIntlProvider(Page)

export const query = graphql`
  query($language: String!) {
#    summaryImage: file(relativePath: { eq: "bck-backend.jpg" }) {
#      ...SummaryImage
#    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.(services|other_services|support|consulting)/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
